
import { mapWritableState } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useMarketStore } from '@/stores/market'
import { useCustomer } from '@/stores/customer'
import { useContext } from '@nuxtjs/composition-api'
import startObserverLoading from '@/mixins/startObserverLoading'

export default {
  name: 'MPPopularSellers',
  mixins: [startObserverLoading],
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    const dcmStore = useDcmStore()
    const { $device } = useContext()

    let slidePerPage = 0
    let keenSliderOptions = {}
    if ($device && $device.isMobile && !$device.isCrawler) {
      slidePerPage = 2
      keenSliderOptions = {
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    } else if ($device && $device.isTablet && !$device.isCrawler) {
      slidePerPage = 3
      keenSliderOptions = {
        rubberband: false,
        breakpoints: {
          '(max-width: 768px)': {
            slides: { perView: 2, spacing: 8 }, // Up to 768px
          },
          '(min-width: 769px) and (max-width: 1000px)': {
            slides: { perView: slidePerPage, spacing: 8 }, // From 769px to 1000px
          },
          '(min-width: 1001px)': {
            slides: { perView: slidePerPage + 1, spacing: 8 }, // Greater than 1000px
          },
        },
      }
    } else if ($device && $device.isDesktop && !$device.isCrawler) {
      slidePerPage = 5

      keenSliderOptions = {
        breakpoints: {
          '(orientation: portrait)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(orientation: portrait) and (min-width: 1024px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(orientation: landscape) and (min-width: 1200px)': {
            slides: { perView: 5, spacing: 8 },
          },
        },
        slides: { perView: slidePerPage, spacing: 8 },
        rubberband: false,
      }
    }
    return {
      marketStore,
      keenSliderOptions,

      slidePerPage,
      customerStore,
      dcmStore,
    }
  },
  data() {
    return {
      waitingToLoad: true,
    }
  },
  async fetch() {
    if (this.customerStore.isBot) {
      await this.startLoadingPopularSellers()
    }
  },
  computed: {
    ...mapWritableState(useDcmStore, ['popular_sellers']),
    isShowingPopularBrands() {
      return this.popular_sellers?.items
        .filter(item => {
          return !item.is_hidden
        })
        .sort((a, b) => a.seq_no - b.seq_no)
    },
    showComponent() {
      return (
        this.popular_sellers.items.length && !this.popular_sellers?.is_hidden
      )
    },
    isClickableBlock() {
      return this.popular_sellers?.is_clickable
    },
  },
  watch: {
    showComponent(val) {
      if (val) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs?.MPPopularSellersSlider?.updateSlider()
          }, 100)
        })
      }
    },
  },
  mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.landingPopularSellers,
        this.startLoadingPopularSellers,
      )
    }
  },

  methods: {
    async startLoadingPopularSellers() {
      await this.dcmStore.getPopularSellers()
      this.waitingToLoad = false
      // if (this.showComponent) {
      //
      // }
    },
    mainSeller(brand) {
      const name = brand.action_url.split('/').slice(-1)[0]
      const name_merchant = name.replace(/^[\d]+[-]/, '')
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'bt_main_seller',
          seller_name: name_merchant,
        })
      }
      window.location = brand.action_url
    },
  },
}
